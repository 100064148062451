import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/usersList/fields";
import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";

export const tableHeaders = [
  headerCheckboxObject(),
  {
    text: "Идентификатор",
    defaultWidth: 150,
    tooltip: "Идентификатор пользователя",
    value: fields.USER_ID,
    isSorted: false,
    isHandle: true,
    isHiddenByDefault: true,
    id: uuid(),
  },
  {
    text: "Логин",
    defaultWidth: 250,
    tooltip: "Логин для входа в Систему",
    value: fields.USER_LOGIN,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Роли",
    defaultWidth: 300,
    tooltip: "Роли пользователя",
    value: fields.USER_ROLES,
    isLink: true,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Дата последнего входа",
    tooltip: "Дата последнего входа",
    defaultWidth: 180,
    value: fields.LAST_LOGIN_TIME,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  { text: "ФИО", defaultWidth: 200, tooltip: "ФИО", value: fields.USER_FULL_NAME, isSorted: true, isHandle: true, id: uuid() },
  {
    text: "Должность",
    defaultWidth: 150,
    isHiddenByDefault: true,
    tooltip: "Должность пользователя",
    value: fields.USER_POSITION,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Подразделение",
    defaultWidth: 180,
    tooltip: "Структурное подразделение пользователя",
    value: fields.USER_DIVISION,
    isHiddenByDefault: true,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Телефон",
    defaultWidth: 160,
    tooltip: "Номер телефона пользователя",
    isHiddenByDefault: true,
    value: fields.USER_PHONE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Электронная почта",
    defaultWidth: 220,
    tooltip: "Электронная почта пользователя",
    value: fields.USER_EMAIL,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Дата создания",
    defaultWidth: 180,
    tooltip: "Дата создания пользователя",
    value: fields.USER_SAVE_DATE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Статус",
    defaultWidth: 160,
    tooltip: "Может принимать следующие значения: Активен, Удалён, Заблокирован",
    value: fields.USER_STATUS,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "IP-адрес",
    defaultWidth: 160,
    tooltip: "IP-адрес",
    value: fields.LAST_LOGIN_IP,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Архивы/фонды",
    tooltip: "Архивы/фонды",
    defaultWidth: 200,
    value: fields.USER_FUND,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
];
