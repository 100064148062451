import { render, staticRenderFns } from "./UserFilters.vue?vue&type=template&id=b1d948e8&scoped=true&"
import script from "./UserFilters.vue?vue&type=script&lang=ts&"
export * from "./UserFilters.vue?vue&type=script&lang=ts&"
import style0 from "./UserFilters.vue?vue&type=style&index=0&id=b1d948e8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1d948e8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
