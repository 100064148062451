export const elementUserStatuses = [
  {
    title: "Активные",
    value: "ACTIVE",
  },
  {
    title: "Удаленные",
    value: "DELETED",
  },
  {
    title: "Заблокированные",
    value: "BLOCKED",
  },
];
