



















































































import { defineComponent, ref } from "@vue/composition-api";
import TextFilter from "@monorepo/uikit/src/components/tableViews/TextFilter.vue";
import Autocomplete from "@monorepo/uikit/src/components/tableViews/Autocomplete.vue";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";
import SelectUsersFilter from "@monorepo/uikit/src/components/common/Select/SelectUsersFilter.vue";
import { elementUserStatuses } from "@monorepo/informationSecurity/src/views/SystemAccesses/constants/elementUserStatuses";
import { mapActions, mapGetters } from "vuex";
import ToggleFilters from "@monorepo/uikit/src/components/tableViews/ToggleFilters.vue";
import { defaultFilterPlaceholder } from "@monorepo/utils/src/constants";
import { fields } from "@monorepo/utils/src/variables/projectsData/usersList/fields";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/systemAccesses/filtersElements";
import { convertToSelectItems } from "@monorepo/utils/src/utils/convertToSelectItems";
import useTemplateFilters from "@monorepo/utils/src/composables/useTemplateFilters";
import useFilter from "@monorepo/utils/src/composables/useFilter";
import useAutocomplete from "@monorepo/utils/src/composables/useAutocomplete";

const autocompleteFiltersKeys = [fields.USER_LOGIN, fields.USER_FULL_NAME, fields.USER_EMAIL];
const setCurrentFilters = () => ({
  [fields.USER_ROLES_ID]: [],
  [fields.USER_STATUS]: [],
  [fields.USER_LOGIN]: {},
  [fields.USER_FULL_NAME]: {},
  [fields.USER_EMAIL]: {},
});

export default defineComponent({
  name: "UserFilters",
  components: {
    SelectFilter,
    SelectUsersFilter,
    ToggleFilters,
    TextFilter,
    Autocomplete,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      elementUserStatuses,
      defaultFilterPlaceholder,
      fields,
      filtersElements,
      autocompleteFiltersKeys,
      selectTop: 30,
    };
  },
  computed: {
    ...mapGetters("systemAccessesView", ["currentRoles", "fieldFilters", "isTableLoading", "isLoadingToggleFilters"]),
    currentRolesLib(): { value: string; title: string }[] {
      return convertToSelectItems(this.currentRoles, "label", "id");
    },
  },
  methods: {
    ...mapActions("systemAccessesView", [
      "addFilter",
      "refreshScroll",
      "clearFilters",
      "getAutocompleteByLogin",
      "getAutocompleteByFullName",
      "getAutocompleteByEmail",
    ]),
  },
  setup(props, context) {
    const { root } = context;
    root.$store.dispatch("systemAccessesView/getCurrentRolesList");
    const currentFilters = ref((setCurrentFilters as () => Record<string, unknown>)());
    const notHiddenKeys = ref([] as string[]);
    const isShowToggle = ref(false);
    const autocompleteItemsResultLists = ref({} as { [key: string]: Record<string, unknown>[] });
    const isLoadingAutocomplete = ref(false);
    useTemplateFilters(root, "systemAccessesView", currentFilters);
    const { filter, hiddenRowsStyleObj, openHiddenFilterOnStart, toggleOpenFilter, clearFiltersCb, refresh, updateFiltersDivHeight } = useFilter(
      context,
      props as {
        value: boolean;
      },
      "systemAccessesView",
      {},
      currentFilters,
      setCurrentFilters,
      filtersElements as { value: string; title: string }[],
      notHiddenKeys,
      isShowToggle
    );
    const { getAutocompleteItems } = useAutocomplete(
      root,
      "systemAccessesView",
      currentFilters,
      autocompleteItemsResultLists,
      isLoadingAutocomplete,
      autocompleteFiltersKeys as string[]
    );

    return {
      filter,
      hiddenRowsStyleObj,
      openHiddenFilterOnStart,
      toggleOpenFilter,
      clearFiltersCb,
      refresh,
      updateFiltersDivHeight,
      currentFilters,
      notHiddenKeys,
      isShowToggle,
      getAutocompleteItems,
      autocompleteItemsResultLists,
      isLoadingAutocomplete,
    };
  },
});
