


































































































import { defineComponent, ComponentInstance, computed } from "@vue/composition-api";
import OpenCardButton from "@monorepo/uikit/src/components/tableViews/OpenCardButton.vue";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { mapActions, mapGetters } from "vuex";
import { IUser } from "@monorepo/informationSecurity/src/views/SystemAccesses/types/IUser";
import { IRoleAPI } from "@monorepo/informationSecurity/src/views/SystemAccesses/types/IRole";
import moment from "moment";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { fields } from "@monorepo/utils/src/variables/projectsData/usersList/fields";
import { headerCheckboxValue } from "@monorepo/utils/src/variables/headerCheckboxValue";
import useTable from "@monorepo/utils/src/composables/useTable";
import useTableCells from "@monorepo/utils/src/composables/useTableCells";
import useTableAnimation from "@monorepo/utils/src/composables/useTableAnimation";

export default defineComponent({
  components: {
    OpenCardButton,
  },
  data() {
    return {
      fields,
      headerCheckboxValue,
    };
  },
  props: {
    tableHeaders: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("systemAccessesView", ["users", "selectedIds", "isSelectAll", "openedUserId", "archivesTree"]),
    ...mapGetters("systemAccessesView", { data: "users", getCells: "cells" }),
    resultData(): IUser[] {
      return this.users.map((item: IUser) => {
        const archiveIds = (item.archiveList || []).map((archive) => archive.id);
        const fundIds = (item.fundList || []).map((fund) => fund.id);
        const accessesTree = (this.archivesTree || []).map((archive: any) => ({
          ...archive,
          isHasAccess: archiveIds.includes(archive.id),
          funds: (archive.funds || []).map((fund: any) => ({
            ...fund,
            isHasAccess: fundIds.includes(fund.id),
          })),
        }));
        return {
          ...item,
          [fields.USER_SAVE_DATE]: moment((item as unknown as Record<string, unknown>)[fields.USER_SAVE_DATE] as string).format("YYYY-MM-DD"),
          [fields.LAST_LOGIN_TIME]: (item as unknown as Record<string, unknown>)[fields.LAST_LOGIN_TIME]
            ? moment((item as unknown as Record<string, unknown>)[fields.LAST_LOGIN_TIME] as string).format("YYYY-MM-DD")
            : "",
          [fields.USER_STATUS]: (item as unknown as Record<string, unknown>)[fields.USER_DELETED]
            ? "Удален"
            : !item.active
            ? "Заблокирован"
            : "Активен",
          [fields.USER_FUND]: accessesTree
            .reduce((result: any[], current: any) => {
              if (current.isHasAccess && (!current.funds?.length || current.funds.every((fund: any) => fund.isHasAccess))) {
                result.push(current.shortName || "");
                return result;
              }
              current.funds
                .filter((fund: any) => fund.isHasAccess)
                .sort((a: any, b: any) => (a.shortName || "").localeCompare(b.shortName || ""))
                .forEach((fund: any) => {
                  result.push(fund.shortName || "");
                });
              return result;
            }, [])
            .join(", "),
        };
      });
    },
    isShowActivate(): boolean {
      return isAuthorityExist(this.user, authorities.USER_ACTIVATE);
    },
    isShowDeactivate(): boolean {
      return isAuthorityExist(this.user, authorities.USER_DEACTIVATE);
    },
  },
  methods: {
    ...mapActions("systemAccessesView", ["setSelectedIds", "setIsSelectAll", "addOpenedUserId", "unblockUser", "blockUser"]),
    openModal(value: string, event: Record<string, unknown>, role?: IRoleAPI) {
      switch (value) {
        case "openModal":
          if (event[fields.USER_ID]) {
            this.addOpenedUserId(event[fields.USER_ID]);
            this.$emit(value, event[fields.USER_IS_DELETED]);
          }
          break;
        case "openAuditLog":
          this.$router.push({
            path: "/information-security/information-security-log",
            query: { initiator: event[fields.USER_LOGIN] as string },
          });
          break;
        case "openRole":
          if (role) {
            this.$router.replace({ name: "systemAccesses", params: { tab: "roles", roleId: role.id.toString() } });
          }
          break;
        default:
          break;
      }
    },
    openBlockModal(event: Record<string, unknown>) {
      if (!event[fields.USER_IS_ACTIVE]) {
        eventBus.$emit(
          MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
          formModalPayload(true, "UnblockUserModal", { cbOnUnblock: () => this.cbOnUnblock(event) })
        );
      } else {
        eventBus.$emit(
          MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
          formModalPayload(true, "BlockUserModal", { cbOnBlock: (config: { selectInfo: string; date: string }) => this.cbOnBlock(event, config) })
        );
      }
    },
    async cbOnBlock(event: Record<string, unknown>, config: { selectInfo: string; date: string }) {
      await this.blockUser({
        id: event[fields.USER_ID],
        blockUntil: config.selectInfo !== "all" ? moment(config.date, "YYYY-MM-DD").toISOString() : undefined,
      });
      return event;
    },
    async cbOnUnblock(event: Record<string, unknown>) {
      await this.unblockUser(event[fields.USER_ID]);
    },
    isShowActivateBtn(event: Record<string, unknown>): boolean {
      if (event[fields.USER_IS_ACTIVE]) {
        return this.isShowActivate;
      } else {
        return this.isShowDeactivate;
      }
    },
  },
  setup(props: Record<string, unknown>, { root }: { root: ComponentInstance }) {
    const tableHeaders = computed(() => props.tableHeaders as unknown[]);
    const { clickCheckbox, isElementDeleted, selectedIdsElements } = useTable(root, "systemAccessesView", { dataField: "users" });
    const { headers } = useTableCells(root, "systemAccessesView", tableHeaders);
    const { isShowAnimation, clearAnimation, addAnimation } = useTableAnimation(root);
    return {
      clickCheckbox,
      isElementDeleted,
      selectedIdsElements,
      headers,
      isShowAnimation,
      clearAnimation,
      addAnimation,
    };
  },
});
