var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',{staticClass:"monitoring-table user-table monitoring-table__content",attrs:{"id":"virtual-scroll-table"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.resultData),function(event){return _c('tr',{key:event[_vm.fields.USER_ID],class:[
          'monitoring-table__tr',
          { 'monitoring-table__tr-active': _vm.selectedIdsElements[event[_vm.fields.USER_ID]] || _vm.openedUserId === event[_vm.fields.USER_ID] } ],attrs:{"data-test-id":"tableRow"},on:{"mousemove":_vm.addAnimation,"mouseleave":_vm.clearAnimation}},_vm._l((_vm.headers),function(header,index){return _c('td',{key:("" + (event[_vm.fields.USER_ID]) + (header.id)),class:"monitoring-table__td pl-3 pr-3 pt-2 pb-2",style:({ width: header.width ? ((header.width) + "px") : '' }),attrs:{"data-test-id":"tableCell"}},[(!index)?_c('OpenCardButton',{class:[
              'user-table__open-card-btn',
              {
                'user-table__open-card-btn_right-fix': event[_vm.fields.USER_IS_ACTIVE],
                'user-table__open-card-btn_deleted': event[_vm.fields.USER_DELETED],
              } ],attrs:{"title":"Открыть карточку"},on:{"open":function($event){return _vm.openModal('openModal', event)}}}):_vm._e(),(!index)?_c('OpenCardButton',{class:[
              'user-table__open-audit-view-btn',
              {
                'user-table__open-audit-view-btn_right-fix': event[_vm.fields.USER_IS_ACTIVE],
                'user-table__open-audit-view-btn_deleted': event[_vm.fields.USER_DELETED],
              } ],attrs:{"title":"Открыть Журнал аудита"},on:{"open":function($event){return _vm.openModal('openAuditLog', event)}}}):_vm._e(),(!index && !event[_vm.fields.USER_DELETED] && _vm.isShowActivateBtn(event))?_c('OpenCardButton',{attrs:{"title":event[_vm.fields.USER_IS_ACTIVE] ? 'Заблокировать' : 'Разблокировать'},on:{"open":function($event){return _vm.openBlockModal(event)}}}):_vm._e(),(header.value === _vm.headerCheckboxValue)?_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"data-test-id":"tableCellCheckbox","ripple":false,"input-value":_vm.selectedIdsElements[event[_vm.fields.USER_ID]]},on:{"click":function($event){$event.preventDefault();return _vm.clickCheckbox(event[_vm.fields.USER_ID])}}}):(header.value === _vm.fields.USER_LOGIN)?_c('div',{staticClass:"d-flex align-center"},[(event[_vm.fields.USER_DELETED])?_c('v-tooltip',{attrs:{"z-index":"200","fixed":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red","size":"24"}},[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('div',{staticClass:"user-table__tooltip"},[_vm._v("Пользователь удален")])]):(!event[_vm.fields.USER_IS_ACTIVE])?_c('v-tooltip',{attrs:{"z-index":"200","fixed":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red","size":"20"}},[_vm._v("mdi-block-helper")])],1)]}}],null,true)},[_c('div',{staticClass:"user-table__tooltip"},[_vm._v("Пользователь заблокирован")])]):_vm._e(),_c('span',{class:{ 'ml-8': !event[_vm.fields.USER_DELETED] && event[_vm.fields.USER_IS_ACTIVE] }},[_vm._v(_vm._s(event[header.value]))])],1):(header.value === _vm.fields.USER_ROLES)?_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((event[header.value]),function(role,idx){return _c('div',{key:role.id,staticClass:"table__link user-table__role",on:{"click":function($event){$event.stopPropagation();return _vm.openModal('openRole', event, role)}}},[_vm._v(" "+_vm._s(role.label)),(idx !== event[header.value].length - 1)?_c('span',{staticClass:"mr-1"},[_vm._v(",")]):_vm._e()])}),0):_c('div',[_vm._v(" "+_vm._s(event[header.value] || "-")+" ")])],1)}),0)}),0)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }