




import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/systemAccesses/tableHeaders";
import ToggleTableCells from "@monorepo/uikit/src/components/tableViews/ToggleTableCells.vue";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/systemAccesses/viewTitle";

export default defineComponent({
  name: "ToggleTableCellsContainer",
  components: {
    ToggleTableCells,
  },
  data() {
    return {
      viewUniqKey,
      tableHeaders,
    };
  },
  computed: {
    ...mapGetters("systemAccessesView", ["cells"]),
  },
  methods: {
    ...mapActions("systemAccessesView", ["setCells"]),
  },
});
