




























import { defineComponent } from "@vue/composition-api";
import { cloneDeep } from "lodash";
import moment from "moment";
import { IDocument } from "@monorepo/inventory/src/views/EadView/types/IDocument";
import { IEadElement } from "@monorepo/inventory/src/views/EadView/types/eadElement";

export default defineComponent({
  name: "DocumentTree",
  props: {
    items: {
      type: Object,
      required: true,
    },
    element: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    documents(): IDocument[] {
      return [cloneDeep(this.items as IDocument)];
    },
    date(): (date: string) => string {
      return (date: string) => {
        return moment(date).format("YYYY-MM-DD");
      };
    },
    statusColor(): (status: string) => string | null {
      return (status) => {
        if (!status) {
          return null;
        }

        switch (status) {
          case "Получен":
            return "#00A459";
          case "Не получен":
            return "#D34039";
          case "Удалён":
            return "#D34039";
          default:
            return null;
        }
      };
    },
  },
  methods: {
    customizableDocument(item: Partial<IDocument>) {
      if (item.ead && item.ead.length) {
        item.ead.forEach((ead) => {
          (ead as IEadElement & { file: boolean }).file = true;
        });
      }
      if (item.subSection && item.subSection.length) {
        item.subSection.forEach((document) => {
          (document as IDocument & { folder: boolean }).folder = true;
        });
      }
      item.name = item?.name ? item?.name : this.element.header;
      (item.subSection as (IDocument | IEadElement)[]) = [...(item?.subSection || []), ...(item?.ead || [])];
      delete item?.ead;
      return item;
    },
    formattedDocuments(documents: IDocument[]) {
      return documents?.map((item) => {
        if ((item as (IDocument | IEadElement) & { file: boolean })?.file) {
          return item;
        }
        const el = this.customizableDocument(item);
        if (el?.subSection && el?.subSection.length) {
          this.formattedDocuments(el.subSection);
        }
        return item;
      });
    },
    openDocument(id: string | number) {
      this.$emit("open-table-view", id);
    },
  },
  data() {
    return {
      tree: [],
    };
  },
});
