import { fields } from "@monorepo/utils/src/variables/projectsData/usersList/fields";

export const filtersElements = [
  {
    value: fields.USER_LOGIN,
    title: "Логин",
  },
  {
    value: fields.USER_FULL_NAME,
    title: "ФИО",
  },
  {
    value: fields.USER_ROLES,
    title: "Роли",
  },
  {
    value: fields.USER_STATUS,
    title: "Статус",
  },
  {
    value: fields.USER_EMAIL,
    title: "Электронная почта",
  },
];
